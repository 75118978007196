import React from "react"
import {injectIntl} from "react-intl"
import withLayout from "../layout"
import Hero from '../components/Hero'
import SEO from "../components/SEO"
import FullWidthMessage from "../components/FullWidthMessage"
import CaseList from "../components/CaseList"
//Videos
import acea from '../images/portfolio/Acea.mp4'
import maui from '../images/portfolio/Mauijim.mp4'
import bancaAnagni from '../images/portfolio/BA.mp4'
import enel from '../images/portfolio/Enel.mp4'
import ploom from '../images/portfolio/Ploom.mp4'
import mantegna from '../images/portfolio/Mantegna.mp4'
import audi from '../images/portfolio/Audi.mp4'
import translated from '../images/portfolio/Translated.mp4'
import insieme from '../images/portfolio/Insieme.mp4'
import fiorentina from '../images/portfolio/Fiorentina.mp4'
import jago from '../images/portfolio/Jago.mp4'
import bysmart from '../images/portfolio/Bysmart.mp4'
import epicTv from '../images/portfolio/EpicTv.mp4'
//Thumbs
import AceaThumb from '../images/portfolio/ACEA_thumbnail.webp'
import MantegnaThumb from '../images/portfolio/MANTEGNA_thumbnail.webp'
import PloomThumb from '../images/portfolio/PLOOM_thumbnail.webp'
import EnelThumb from '../images/portfolio/ENEL_thumbnail.webp'
import BancaAnagniThumb from '../images/portfolio/BANCA AGAGNI_thumbnail.webp'
import audiThumb from '../images/portfolio/AUDI_thumbnail.webp'
import mauiThumb from '../images/portfolio/MAUI-JIM_thumbnail.webp'
import translatedThumb from '../images/portfolio/TRANSLATED_thumbnail.webp'
import insiemeThumb from '../images/portfolio/INSIEME_thumbnail.webp'
import FiorentinaThumb from '../images/portfolio/FIORENTINA_thumb.webp'
import JagoThumb from '../images/portfolio/Jago.webp'
import BysmartThumb from '../images/portfolio/Bysmart.webp'
import EpicTvThumb from '../images/portfolio/EpicTv.webp'
import {Fade} from 'react-reveal'

const CLIENTS = [
    {
        video: translated,
        title: 'portfolio.translated.title',
        poster: translatedThumb,
        subtitle: 'portfolio.translated.description',
        to: '/case-study/translated',
        types: ['data_analysis_consulting','design_dev']
    },
    {
        video: jago,
        title: 'portfolio.jago.title',
        poster: JagoThumb,
        subtitle: 'portfolio.jago.description',
        to: '/case-study/jago',
        types: ['design_dev', 'creative_communication']
    },
    {
        video: audi,
        title: 'portfolio.audi.title',
        poster: audiThumb,
        subtitle: 'portfolio.audi.description',
        to: '/case-study/audi',
        types: ['data_analysis_consulting']
    },
    {
        video: epicTv,
        title: 'portfolio.epic_tv.title',
        poster: EpicTvThumb,
        subtitle: 'portfolio.epic_tv.description',
        to: '/case-study/epic-tv',
        types: ['marketing_strategy', 'data_analysis_consulting']
    },
    {
        video: maui,
        title: 'portfolio.maui.title',
        poster: mauiThumb,
        subtitle: 'portfolio.maui.description',
        to: '/case-study/maui',
        types: ['data_analysis_consulting','design_dev']
    },
    {
        video: bancaAnagni,
        title: 'portfolio.bancaAnagni.title',
        poster: BancaAnagniThumb,
        subtitle: 'portfolio.bancaAnagni.description',
        to: '/case-study/bancaAnagni',
        types: ['design_dev']
    },
    {
        video: insieme,
        title: 'portfolio.insieme.title',
        poster: insiemeThumb,
        subtitle: 'portfolio.insieme.description',
        to: '/case-study/insieme',
        types: ['design_dev']
    },
    {
        video: mantegna,
        title: 'portfolio.mantegna.title',
        poster: MantegnaThumb,
        subtitle: 'portfolio.mantegna.description',
        to: '/case-study/mantegna',
        types: ['design_dev', 'creative_communication', 'marketing_strategy']
    },
    {
        video: enel,
        title: 'portfolio.enel.title',
        poster: EnelThumb,
        subtitle: 'portfolio.enel.description',
        to: '/case-study/enel',
        types: ['design_dev']
    },
    {
        video: acea,
        title: 'portfolio.acea.title',
        subtitle: 'portfolio.acea.description',
        poster: AceaThumb,
        to: '/case-study/acea',
        types: ['design_dev']
    },
    {
        video: ploom,
        title: 'portfolio.ploom.title',
        poster: PloomThumb,
        subtitle: 'portfolio.ploom.description',
        to: '/case-study/jti',
        types: ['design_dev']
    },
    {
        video: fiorentina,
        title: 'portfolio.fiorentina.title',
        poster: FiorentinaThumb,
        subtitle: 'portfolio.fiorentina.description',
        to: '/case-study/fiorentina',
        types: ['design_dev']
    },
    {
        video: bysmart,
        title: 'portfolio.bysmart.title',
        poster: BysmartThumb,
        subtitle: 'portfolio.bysmart.description',
        to: '/case-study/bysmart',
        types: ['design_dev', 'creative_communication', 'marketing_strategy']
    }
]

const PortfolioPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'portfolio.meta_keywords'}).split(',') || []

    return <>
        <SEO title={intl.formatMessage({id: 'portfolio.meta_title'})} description={intl.formatMessage({id: 'portfolio.meta_description'})} keywords={keywords} lang={intl.locale}/>
        <Hero title={"portfolio.title"} subtitle={"portfolio.title_2"} thinChild={'3'} paragraph={"portfolio.paragraph"} isDark={false}/>
        <div className="d-block d-md-none">
            <CaseList list={CLIENTS} intl={intl}/>
        </div>
        <div className="d-none d-md-block">
            <Fade bottom delay={800}>
                <CaseList list={CLIENTS} intl={intl}/>
            </Fade>
        </div>
        <FullWidthMessage title="portfolio.tell_vision_title" blur={true}
                          text1="portfolio.tell_vision_text_1" text2="portfolio.tell_vision_text_2"
                          ctaLink="/contact-us/" ctaText="portfolio.contact_us" intl={intl}/>
    </>
}
const customProps = {
    localeKey: "portfolio", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(PortfolioPage))
